/* =============================================================================
 * Type
============================================================================= */
html:root {
	/* Font family */
	--type-ff-default:"Avenir", Arial, sans-serif;
	--type-ff-header:"MillerDisplay", Georgia, serif;

	/* Sizes */
	--type-sz-default:clamp(0.9375rem, 0.9034rem + 0.1705vw, 1.0313rem);
	--type-lh-default:clamp(1.25rem, 1.2045rem + 0.2273vw, 1.375rem);

	--type-sz-5:clamp(0.625rem, 0.5795rem + 0.2273vw, 0.75rem);
	--type-lh-5:clamp(0.75rem, 0.7045rem + 0.2273vw, 0.875rem);

	--type-sz-10:clamp(0.75rem, 0.7045rem + 0.2273vw, 0.875rem);
	--type-lh-10:clamp(1.25rem, 1.2045rem + 0.2273vw, 1.375rem);

	--type-sz-20:clamp(1rem, 0.9091rem + 0.4545vw, 1.25rem);
	--type-lh-20:clamp(1.375rem, 1.2841rem + 0.4545vw, 1.625rem);

	--type-sz-40:clamp(1.25rem, 1.2045rem + 0.2273vw, 1.375rem);
	--type-lh-40:clamp(1.375rem, 1.2841rem + 0.4545vw, 1.625rem);

	--type-sz-50:clamp(1.375rem, 1.3295rem + 0.2273vw, 1.5rem);
	--type-lh-50:clamp(1.5rem, 1.4091rem + 0.4545vw, 1.75rem);

	--type-sz-60:clamp(1.375rem, 1.2386rem + 0.6818vw, 1.75rem);
	--type-lh-60:clamp(1.75rem, 1.6591rem + 0.4545vw, 2rem);

	--type-sz-80:clamp(1.875rem, 1.6932rem + 0.9091vw, 2.375rem);
	--type-lh-80:clamp(2rem, 1.8182rem + 0.9091vw, 2.5rem);

	--type-sz-100:clamp(2.375rem, 2.1477rem + 1.1364vw, 3rem);
	--type-lh-100:clamp(2.5rem, 2.2045rem + 1.4773vw, 3.3125rem);

	--type-sz-120:clamp(2.625rem, 2.3977rem + 1.1364vw, 3.25rem);
	--type-lh-120:clamp(2.75rem, 2.4773rem + 1.3636vw, 3.5rem);

	--type-sz-140:clamp(3.5rem, 3.2727rem + 1.1364vw, 4.125rem);
	--type-lh-140:clamp(3.625rem, 3.3523rem + 1.3636vw, 4.375rem);
}
