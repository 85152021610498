/* =============================================================================
 * Themes
============================================================================= */

/**
 * Default
 */
html:root {
	--control-bg:var(--hippie);
	--control-bg-inverted:var(--white);
	--control-text:var(--white);
	--control-text-inverted:var(--gray-1);
	--hero-control-bg:var(--calypso);
	--text-link:var(--calypso);
	--icon-subtle:rgba(0, 0, 0, 0.2);
	--text:var(--gray-1);
	--icon:var(--hippie);
	--block-bg:var(--white);
	--block-bg-contrast:var(--gray-5);
	--contrast:var(--hippie);
}
.variant--primary {
	--text:var(--white);
	--text-link:var(--white);
	--text-contrast:var(--gray-1);
	--block-bg:var(--hippie);
	--block-bg-contrast:var(--hippie-50);
	--control-bg:var(--calypso);
	--control-text:var(--white);
	--control-bg-inverted:var(--white);
	--control-text-inverted:var(--gray-1);
	--error:var(--white);
	--icon:var(--white);
	--icon-subtle:rgba(255, 255, 255, 0.6);
}
.variant--secondary {
	--block-bg:var(--pearl-25);
}
.variant--tertiary {
	--block-bg:var(--pearl-15);
}

.variant--wtc-blue {
	background-color: #4D8BBE !important;
	color :#ffffff !important;
}

.variant--wtc-orange {
	background-color: #F9A25E !important;
	color: #323333 !important;
}

/**
 * Calypso
 */
.theme--calypso[class] {
	--control-bg:var(--calypso);
	--hero-control-bg:var(--calypso);
	--icon:var(--calypso);
	--contrast:var(--calypso);
}
.theme--calypso .variant--primary {
	--block-bg:var(--calypso);
	--block-bg-contrast:var(--calypso-25);
	--control-bg:var(--white);
	--control-bg-inverted:var(--calypso-50);
	--control-text:var(--calypso);
}
.theme--calypso .variant--secondary {
	--block-bg:var(--calypso-25);
}
.theme--calypso .variant--tertiary {
	--block-bg:var(--calypso-15);
}

/**
 * Calypso 50
 */
.theme--calypso-50[class] {
	--control-bg:var(--calypso);
	--hero-control-bg:var(--calypso);
	--icon:var(--calypso);
	--contrast:var(--calypso);
}
.theme--calypso-50 .variant--primary {
	--block-bg:var(--calypso-50);
	--block-bg-contrast:var(--calypso);
	--text-contrast:var(--white);
	--text:var(--gray-1);
	--control-bg:var(--white);
	--control-text:var(--calypso);
}
.theme--calypso-50 .variant--secondary {
	--block-bg:var(--calypso-25);
}
.theme--calypso-50 .variant--tertiary {
	--block-bg:var(--calypso-15);
}

/**
 * Petrol
 */
.theme--petrol[class] {
	--control-bg:var(--petrol);
	--hero-control-bg:var(--petrol);
	--icon:var(--petrol);
	--contrast:var(--petrol);
}
.theme--petrol .variant--primary {
	--block-bg:var(--petrol);
	--block-bg-contrast:var(--petrol-25);
	--control-bg:var(--white);
	--control-bg-inverted:var(--petrol-50);
	--control-text:var(--petrol);
}
.theme--petrol .variant--secondary {
	--block-bg:var(--petrol-25);
}
.theme--petrol .variant--tertiary {
	--block-bg:var(--petrol-15);
}

/**
 * Petrol 50
 */
.theme--petrol-50[class] {
	--control-bg:var(--petrol);
	--hero-control-bg:var(--petrol);
	--icon:var(--petrol);
	--contrast:var(--petrol);
}
.theme--petrol-50 .variant--primary {
	--block-bg:var(--petrol-50);
	--block-bg-contrast:var(--petrol);
	--text-contrast:var(--white);
	--text:var(--gray-1);
	--control-bg:var(--white);
	--control-bg-inverted:var(--petrol);
	--control-text:var(--petrol);
}
.theme--petrol-50 .variant--secondary {
	--block-bg:var(--petrol-25);
}
.theme--petrol-50 .variant--tertiary {
	--block-bg:var(--petrol-15);
}

/**
 * Viridian
 */
.theme--viridian[class] {
	--control-bg:var(--viridian);
	--hero-control-bg:var(--viridian);
	--icon:var(--viridian);
	--contrast:var(--viridian);
}
.theme--viridian .variant--primary {
	--block-bg:var(--viridian);
	--block-bg-contrast:var(--viridian-25);
	--control-bg:var(--white);
	--control-bg-inverted:var(--viridian-50);
	--control-text:var(--viridian);
}
.theme--viridian .variant--secondary {
	--block-bg:var(--viridian-25);
}
.theme--viridian .variant--tertiary {
	--block-bg:var(--viridian-15);
}

/**
 * Viridian 50
 */
.theme--viridian-50[class] {
	--control-bg:var(--viridian);
	--hero-control-bg:var(--viridian);
	--icon:var(--viridian);
	--contrast:var(--viridian);
}
.theme--viridian-50 .variant--primary {
	--block-bg:var(--viridian-50);
	--block-bg-contrast:var(--viridian);
	--text-contrast:var(--white);
	--text:var(--gray-1);
	--control-bg:var(--white);
	--control-bg-inverted:var(--viridian);
	--control-text:var(--viridian);
}
.theme--viridian-50 .variant--secondary {
	--block-bg:var(--viridian-25);
}
.theme--viridian-50 .variant--tertiary {
	--block-bg:var(--viridian-15);
}

/**
 * Fuscous 50
 */
.theme--fuscous-50[class] {
	--control-bg:var(--fuscous);
	--hero-control-bg:var(--fuscous);
	--icon:var(--fuscous);
	--contrast:var(--fuscous);
}
.theme--fuscous-50 .variant--primary {
	--block-bg:var(--fuscous-50);
	--block-bg-contrast:var(--fuscous);
	--text-contrast:var(--white);
	--text:var(--gray-1);
	--control-bg:var(--white);
	--control-bg-inverted:var(--fuscous);
	--control-text:var(--fuscous);
}
.theme--fuscous-50 .variant--secondary {
	--block-bg:var(--fuscous-25);
}
.theme--fuscous-50 .variant--tertiary {
	--block-bg:var(--fuscous-15);
}

/**
 * Pearl
 */
.theme--pearl[class] {
	--control-bg:var(--pearl);
	--control-text:var(--gray-1);
	--hero-control-bg:var(--pearl);
	--icon:var(--pearl);
	--contrast:var(--gray-1);
}
.theme--pearl .variant--primary {
	--block-bg:var(--pearl);
	--block-bg-contrast:var(--pearl-25);
	--control-bg:var(--white);
	--control-bg-inverted:var(--fuscous);
	--control-text-inverted:var(--white);
}
.theme--pearl .variant--secondary {
	--block-bg:var(--pearl-25);
}
.theme--pearl .variant--tertiary {
	--block-bg:var(--pearl-15);
}
