/* =============================================================================
 * Common styling for unclassed HTML elements.
============================================================================= */


/* Viewport
----------------------------------------------------------------------------- */
html {
	background:var(--white);
	color:var(--gray-1);
	font-size:16px;
}
body {
	font-size:var(--type-sz-default);
	line-height:var(--type-lh-default);
	font-family:var(--type-ff-default);
}

@media (prefers-reduced-motion:no-preference) {
	html {
		scroll-behavior:smooth;
	}
}

/* Paragraphs
----------------------------------------------------------------------------- */
p {
	margin-top:0;
	margin-bottom:20px;
}


/* Links
----------------------------------------------------------------------------- */
a {
	color:var(--text-link);
}
a:hover,
a:focus {
	color:var(--text-link);
}


/* Headings
----------------------------------------------------------------------------- */
h1 {
	margin:0 0 30px;
	font-size:var(--type-sz-120);
	line-height:var(--type-lh-120);
	font-family:var(--type-ff-header);
}
h2 {
	margin:0 0 20px;
	font-size:var(--type-sz-80);
	line-height:var(--type-lh-80);
	font-family:var(--type-ff-header);
}
h3 {
	margin:0 0 20px;
	font-size:var(--type-sz-60);
	line-height:var(--type-lh-60);
	font-family:var(--type-ff-header);
}
h4 {
	margin:0 0 10px;
	font-size:var(--type-sz-40);
	line-height:var(--type-lh-40);
	font-family:var(--type-ff-header);
}
h5 {
	margin:0 0 10px;
	font-family:var(--type-ff-header);
}
h6 {
	margin:0 0 10px;
	font-family:var(--type-ff-header);
}


/* Lists
 * Global styling of unclassed lists.
 *
 * 1. Remove trailing margins from nested lists.
----------------------------------------------------------------------------- */
dl,
ol,
ul {
	margin-top:0;
	margin-bottom:30px;
}
li > ul,
li > ol {
	margin-bottom:0; /* [1] */
}
dt {
	font-weight:bold;
}
dd + dt {
	margin-top:calc(30px / 2);
}


/* Images
 *
 * 1. Fluid images for responsive purposes.
 * 2. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display:block;`.
 * 3. Keep correct aspect ratio when img is scaled down based on parent width.
----------------------------------------------------------------------------- */
img {
	max-width:100%; /* 1 */
	vertical-align:middle; /* 2 */
}
img[height] {
	height:auto; /* 3 */
}


/* Figure
----------------------------------------------------------------------------- */
figure {
	margin-bottom:30px;
}


/* Forms
----------------------------------------------------------------------------- */
form {
	margin-bottom:30px;
}
fieldset {
	margin-bottom:30px;
}
label {
	display:inline-block;
	margin-bottom:5px;
}
select {
	padding:10px 42px 10px 15px;
	border:1px solid #d8d8d8;
	border:1px solid var(--gray-3);
	border-radius:5px;
	background-color:#fff;
	background-image:url("/gui/images/icons/select-arrow-inverted.svg");
	background-position:86%;
	background-size:10%;
	background-repeat:no-repeat;
	font:inherit;
	font-weight:bold;
	font-size:var(--type-sz-default);
	line-height:var(--type-lh-default);
	font-family:var(--type-ff-default);
	-webkit-appearance:none;
}
select:focus {
	outline:none;
	border-color:var(--calypso);
	box-shadow:0 4px 10px 0 rgba(0, 0, 0, 0.07);
}
input:not([type]),
input[type="text"],
input[type="url"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="search"],
textarea {
	width:100%;
	padding:10px;
	border:1px solid var(--gray-3);
	border-radius:3px;
	background:var(--white);
	color:var(--black);
	font-size:var(--type-sz-default);
	line-height:var(--type-lh-default);
	font-family:var(--type-ff-default);
}
textarea {
	height:200px;
}
button {
	font-size:var(--type-sz-default);
	line-height:var(--type-lh-default);
	font-family:var(--type-ff-default);
}
input[disabled],
textarea [disabled],
button[disabled] {
	cursor:not-allowed;
}
input::-webkit-input-placeholder {
	color:var(--gray-2);
}
input:-ms-input-placeholder {
	color:var(--gray-2);
}
input::-ms-input-placeholder {
	color:var(--gray-2);
}
input::placeholder {
	color:var(--gray-2);
}


/* Tables
----------------------------------------------------------------------------- */
table {
	width:100%;
	margin-bottom:30px;
	border-spacing:0;
	border-collapse:collapse;
}
caption {
	padding-bottom:15px;
	border-bottom:1px solid var(--gray-3);
	font-weight:bold;
	text-align:left;
}
td,
th {
	border-bottom:1px solid var(--gray-3);
	vertical-align:top;
}
th {
	padding:5px 20px 5px 0;
	text-align:left;
}
td,
tbody th {
	padding:15px 20px 15px 0;
}
tfoot {
	font-weight:bold;
}


/* Quotes
----------------------------------------------------------------------------- */
blockquote {
	margin-bottom:30px;
}
blockquote > *:last-child {
	margin-bottom:0;
}


/* Preformatted
----------------------------------------------------------------------------- */
pre {
	margin-bottom:30px;
}


/* Mark
----------------------------------------------------------------------------- */
mark {
	background-color:var(--conch-25);
	color:inherit;
}


/* Iframe
----------------------------------------------------------------------------- */
iframe {
	width:100%;
	border:none;
}


/* Hr
----------------------------------------------------------------------------- */
hr {
	height:1px;
	border:none;
	background-color:var(--gray-3);
}
