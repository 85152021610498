/* =============================================================================
 * Fonts
 *
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face#Browser_compatibility
 * for information about which formats you need to support your target browsers.
============================================================================= */

@font-face {
	font-weight:normal;
	font-family:"MillerDisplay";
	src:url("MillerDisplay/MillerDisplay-Bold_0.otf");
}

@font-face {
	font-weight:bold;
	font-family:"MillerDisplay";
	src:url("MillerDisplay/MillerDisplay-Bold_0.otf");
}

@font-face {
	font-weight:300;
	font-family:"MillerDisplay";
	src:url("MillerDisplay/MillerDisplay-Bold_0.otf");
}

@font-face {
	font-weight:normal;
	font-family:"Avenir";
	src:url("Avenir/AvenirLTStd-Roman.otf");
}

@font-face {
	font-weight:bold;
	font-family:"Avenir";
	src:url("Avenir/AvenirLTStd-Heavy.otf");
}

@font-face {
	font-weight:normal;
	font-style:italic;
	font-family:"Avenir";
	src:url("Avenir/AvenirLTStd-Oblique.otf");
}

@font-face {
	font-weight:normal;
	font-family:"Avenir";
	src:url("Avenir/AvenirLTStd-Roman.otf");
}

@font-face {
	font-weight:300;
	font-family:"Avenir";
	src:url("Avenir/AvenirLTStd-Light.otf");
}

@font-face {
	font-weight:500;
	font-family:"Avenir";
	src:url("Avenir/AvenirLTStd-Medium.otf");
}
