/* =============================================================================
 * Colors
============================================================================= */
html:root {
	--white:#fff;
	--black:#000;

	--gray-1:#333;
	--gray-2:#999;
	--gray-3:#ccc;
	--gray-4:#ededed;
	--gray-5:#f9f9f9;
	--gray-6:#757575;

	/* System colors */
	--success:#27ae60;
	--error:#e74c3c;

	/* Hippie */
	--hippie:#5c8fb4;
	--hippie-50:#aec7da;
	--hippie-25:#d7e3ed;
	--hippie-15:#e7eff4;

	/* Calypso */
	--calypso:#31698b;
	--calypso-50:#98b4c5;
	--calypso-25:#ccdae2;
	--calypso-15:#e1e9ee;

	/* Petrol */
	--petrol:#115e6b;
	--petrol-50:#88afb5;
	--petrol-25:#c4d7da;
	--petrol-15:#dce7e9;

	/* Viridian */
	--viridian:#67887b;
	--viridian-50:#b3c4bd;
	--viridian-25:#d9e2de;
	--viridian-15:#e9eeec;

	/* Conch */
	--conch:#c4d7cc;
	--conch-50:#e2ebe6;
	--conch-25:#f1f5f3;
	--conch-15:#f7f9f8;

	/* Pearl */
	--pearl:#c9bcaf;
	--pearl-50:#e4ded7;
	--pearl-25:#f2efec;
	--pearl-15:#f7f5f3;

	/* Fuscous */
	--fuscous:#4a4a49;
	--fuscous-50:#a5a5a4;
	--fuscous-25:#d2d2d2;
	--fuscous-15:#e4e4e4;

	/* Thatch */
	--thatch:#ceafb5;

	/* Spindle */
	--spindle:#a7cee6;

	/* WTC Colors*/
	--wtc-blue:#4D8BBE;
	--wtc-orange: #F9A25E;
}
